import {
	AppstoreOutlined,
	BarChartOutlined,
	CloudOutlined,
	ShopOutlined,
	TeamOutlined,
	UploadOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout } from 'antd';
import classNames from 'classnames';
import { Suspense, createElement, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../../antCover.css';
import TRPCLayout from '../../utils/TRPCLayout';
import { useUpdateGlobalUserAgent, useUserAgent } from '../../utils/userAgent';
import LoadingUI from '../LoadingUI';
import { ApartmentFooter } from './ApartmentFooter';
import Entry from './Entry';
import style from './layout.module.css';
import { useAppManifest } from './useAppManifest';
import { useTitle } from 'ahooks';
const { Header, Content, Footer, Sider } = Layout;

const items: MenuProps['items'] = [
	UserOutlined,
	VideoCameraOutlined,
	UploadOutlined,
	BarChartOutlined,
	CloudOutlined,
	AppstoreOutlined,
	TeamOutlined,
	ShopOutlined,
].map((icon, index) => ({
	key: String(index + 1),
	icon: createElement(icon),
	label: `nav ${index + 1}`,
}));


function ApartmentContainer() {
	useTitle("laikezhan - 来客栈")
	useUpdateGlobalUserAgent();
	const [collapsed, setCollapsed] = useState(false);

	const { isMobile, isPC } = useUserAgent();
	const location = useLocation();

	useAppManifest();

	const center = (
		<Suspense fallback={<LoadingUI />}>
			<Outlet />
		</Suspense>
	);

	if (
		location.pathname.includes('login') ||
		location.pathname.includes('print')
	) {
		return (
			<TRPCLayout app="apartment" isUseAuth={false}>
				{center}
			</TRPCLayout>
		);
	}

	return (
		<TRPCLayout app="apartment">
			<Layout hasSider={isPC}>
				<Entry setCollapsed={setCollapsed} collapsed={collapsed} />

				<Layout>
					<Content
						className={classNames({
							[style['main-layout'] as unknown as string]: isPC,
							[style.collapsed as unknown as string]: isPC && collapsed,
							[style['not-collapsed'] as unknown as string]:
								isPC && collapsed === false,
						})}
					>
						{center}
					</Content>

					<ApartmentFooter />
				</Layout>
			</Layout>
		</TRPCLayout>
	);
}

export default ApartmentContainer;

import { useState } from 'react';

import { Form, Modal, Radio, Space } from 'antd';
import { TrpcBase } from '../../utils/trpc';
import { useBuildingConfig } from './useBuildingConfig';

export default function useSwitchBuilding() {
	const [isSwitchModalOpen, setIsModalOpen] = useState(false);
	const { data: list } = TrpcBase.apartment.getMyBuildingsConfig.useQuery();
	const [form] = Form.useForm();
	const { mutateAsync, data } = useBuildingConfig();

	const modal = (
		<Modal
			title="楼宇列表"
			open={isSwitchModalOpen}
			onCancel={() => {
				setIsModalOpen(false);
			}}
			onOk={async () => {
				const res = await mutateAsync(form.getFieldsValue());
				console.log(res);
				setIsModalOpen(false);
				location.reload();
			}}
		>
			<Form
				form={form}
				initialValues={{
					buildingId: data?.currentSelectedBuilding,
				}}
			>
				<Form.Item name="buildingId">
					<Radio.Group>
						<Space direction="vertical">
							{list?.map((i) => (
								<Radio key={i.buildingId} value={i.buildingId}>
									{i.buildingName}
								</Radio>
							))}
						</Space>
					</Radio.Group>
				</Form.Item>
			</Form>
		</Modal>
	);

	return { modal, isSwitchModalOpen, setIsModalOpen };
}

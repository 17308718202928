import { Layout, Menu } from 'antd';
import { compact } from 'lodash-es';
import { useState } from 'react';
import { useMatches, useNavigate } from 'react-router';
import { useUserAgent } from '../../utils/userAgent';
import { useRequestHelp } from '../test/RequestHelp';
import styles from './layout.module.css';
import { useBuildingConfig } from './useBuildingConfig';
import useSwitchBuilding from './useSwitchBuilding';

const { Header, Sider } = Layout;

export default function Entry({
	collapsed,
	setCollapsed,
}: {
	collapsed: boolean;
	setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { mutateAsync, data } = useBuildingConfig();
	const { modal, isSwitchModalOpen, setIsModalOpen } = useSwitchBuilding();
	const { isMobile, isPC } = useUserAgent();
	const navigate = useNavigate();

	const matches = useMatches();
	const diagnose = useRequestHelp();

	const MenuItems = (
		<>
			{modal}
			<Menu
				style={{ paddingBottom: '50px' }}
				defaultSelectedKeys={matches.map((i) => i.pathname)}
				mode={isPC ? 'inline' : 'horizontal'}
				defaultOpenKeys={isPC ? ['我的账本'] : []}
				items={compact([
					{
						key: '/apartment/overview',
						label: '总览',
						onClick: () => {
							navigate('/apartment/overview');
						},
					},
					{
						key: '报表管理',
						label: '报表管理',
						children: [
							{
								key: '/apartment/list',
								label: '打印房租单',
								onClick: () => {
									navigate('/apartment/list');
								},
							},
							// {
							//   key: '/apartment/1',
							//   label: '集中抄表',
							//   disabled: true,
							//   onClick: () => {},
							// },
							// {
							//   key: '/apartment/electronChart',
							//   label: '电费图表',
							//   onClick: () => {
							//     navigate('/apartment/electronChart');
							//   },
							// },

							{
								key: '/apartment/recordCheck',
								label: '记录',
								onClick: () => {
									navigate('/apartment/recordCheck');
								},
							},
						],
					},

					{
						key: '我的账本',
						label: '我的账本',
						children: [
							{
								key: '/apartment/stat',
								label: '核对账单',
								onClick: () => {
									navigate('/apartment/stat');
								},
							},

							// {
							// 	key: '/apartment/checkRead',
							// 	label: '核对水电读数',
							// 	onClick: () => {
							// 		navigate('/apartment/checkRead');
							// 	},
							// },

							{
								key: '/apartment/checkUsed',
								label: '核对水电用量',
								onClick: () => {
									navigate('/apartment/checkUsed');
								},
							},
							{
								key: '/apartment/checkBill',
								label: '收支日志',
								onClick: () => {
									navigate('/apartment/checkBill');
								},
							},
						],
					},

					{
						key: '统计图表',
						label: '统计图表',
						children: [
							{
								key: '/apartment/flow/used',
								label: '用度统计',
								onClick: () => {
									navigate('/apartment/flow/used');
								},
							},
							{
								key: '/apartment/flow/amount',
								label: '收款统计',
								onClick: () => {
									navigate('/apartment/flow/amount');
								},
							},
							{
								key: '/apartment/usedByMonth',
								label: '月度水电用量',
								onClick: () => {
									navigate('/apartment/usedByMonth');
								},
							},
						],
					},

					// {
					//   key: '/apartment/entering',
					//   label: '录入',
					//   onClick: () => {
					//     navigate('/apartment/entering');
					//   },
					// },

					(data?.buildings?.length ?? 0) >= 2 && {
						key: '/apartment/switchBuilding',
						label: '切换楼宇',
						onClick: () => {
							// switch
							setIsModalOpen(true);
						},
					},
					{
						key: '房源管理',
						label: '房源管理',
						children: [
							{
								key: '/apartment/buildingConfigure',
								label: '房源配置',
								onClick: () => {
									navigate('/apartment/buildingConfigure');
								},
							},
						],
					},

					{
						key: '帮助',
						label: '帮助',
						children: [
							{
								key: '/apartment/requestHelp',
								label: '诊断',
								onClick: () => {
									diagnose();
								},
							},
							{
								key: '/apartment/helpDraw',
								label: '流程',
								onClick: () => {
									navigate('/apartment/helpDraw');
								},
							},
							{
								key: '/apartment/about',
								label: '关于',
								onClick: () => {
									navigate('/apartment/about');
								},
							},
						],
					},
					{
						key: '/apartment/myInfo',
						label: '账户信息',
						onClick: () => {
							navigate('/apartment/myInfo');
						},
					},
					{
						key: '退出登陆',
						label: '退出登陆',
						onClick: () => {
							// biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
							navigate(`/account/sign-out?url=https://laikezhan.com`);
						},
					},
				])}
			/>
		</>
	);

	if (isMobile) {
		return (
			<Header
				className={`${styles.noPrint} ${styles.header}`}
				style={isMobile ? { padding: 0 } : {}}
			>
				{MenuItems}
			</Header>
		);
	}

	return (
		<Sider
			onMouseEnter={() => {
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.remove('overflow-hidden');
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.add('overflow-y-auto');
			}}
			onMouseLeave={() => {
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.add('overflow-hidden');
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.remove('overflow-y-auto');
			}}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => setCollapsed(value)}
			theme={'light'}
			style={{
				overflow: 'auto',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				bottom: 0,
			}}
		>
			{MenuItems}
		</Sider>
	);
}

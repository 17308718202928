import { useMount } from 'ahooks';
import manifestUrl from './manifest.json?url';

export function useAppManifest() {
	useMount(() => {
		// https://web.dev/articles/add-manifest?hl=zh-cn
		// <link rel="manifest" href="/assets/manifest.json" />
		const a: HTMLLinkElement | null = document.querySelector(
			"link[rel='manifest']",
		);
		if (a) {
			a.href = manifestUrl;
			return;
		}

		const n: HTMLLinkElement = document.createElement('link');
		n.rel = 'manifest';
		n.href = manifestUrl;

		document.head.appendChild(n);
	});
}

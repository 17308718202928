import { Footer } from 'antd/es/layout/layout';
import { useBuildingConfig } from './useBuildingConfig';
import style from './layout.module.css';
import classNames from 'classnames';
import { useUserAgent } from '../../utils/userAgent';

export function ApartmentFooter() {
	const { isPC } = useUserAgent();
	const { currentBuildingConfig } = useBuildingConfig();

	return (
		<Footer
			style={{ textAlign: 'center' }}
			className={classNames({
				[style['main-layout'] as unknown as string]: isPC,
			})}
		>
			{currentBuildingConfig?.buildingName} 物业管理 laikezhan.com 2019-2024
		</Footer>
	);
}
